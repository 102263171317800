.p-dropdown {
  width: 100%;
  border: 2px solid #362866;
  background: transparent;
  border-radius: 100px;

  &:not(:disabled) {
    &:hover {
      border: 2px solid #362866;
    }
    &.p-focus {
      box-shadow: none;
      border: 2px solid #362866;
    }
  }

  .p-icon {
    color: #ffffff;
    width: 12px;
    height: 12px;
  }
  .p-dropdown-trigger {
    //width: auto;
  }

  .p-dropdown-label {
    padding-right: 2px;
    max-width: calc(100vw - 92px);
    &.p-placeholder {
      color: #ffffff;
    }

    img {
      margin-right: 8px;
    }
  }

  &.p-dropdown-clearable {
    .p-dropdown-label {
      padding-right: 19px;
    }
  }
  &.p-dropdown-open {
    .p-dropdown-trigger {
      transform: rotate(180deg);
    }
  }
}

.p-inputtext {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.1em;
  padding: 16px 18px;
  color: #ffffff;
}


.p-dropdown-inline {
  .p-dropdown {
    border: none;
    &:not(:disabled) {
      &:hover {
        border: none;
      }
      &.p-focus {
        box-shadow: none;
        border: none;
      }
    }

    .p-dropdown-trigger {
      width: auto;
      margin-left: 4px;
    }
    .p-dropdown-label {
      padding: 0;
    }
  }
}

.p-dropdown-panel {
  border-radius: 26px;
  background: #10012A linear-gradient(180deg, rgba(39, 18, 90, 0) 0%, rgba(39, 18, 90, 1) 100%);
  overflow: hidden;
  padding: 16px 8px 0;

  .p-dropdown-header {
    padding: 0;
    border: none;
    color: #343a40;
    background: transparent;
    margin: 0;

    .p-dropdown-filter {
      margin-right: 0;
      padding-right: 18px;
    }
  }

  .p-inputtext {
    border-radius: 36px;
    overflow: hidden;
    background: rgba(27, 13, 64, 1);
    border: none;
    &::placeholder {
      text-transform: uppercase;
      color: rgba(159, 146, 225, 1);
    }
    &:enabled {
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: transparent;
      }
    }
  }

  .p-dropdown-items {
    padding: 8px 0;
    .p-dropdown-empty-message {
      color:  rgba(159, 146, 225, 1);
      font-size: 14px;
      line-height: 16px;
    }
    .p-dropdown-item {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.1em;
      padding: 16px 14px;
      color: rgba(159, 146, 225, 1);
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: #ffffff;
            background: transparent;
          }
        }
      }

      &.p-highlight {
        background: transparent;
        color: #ffffff;
      }

      img {
        margin-right: 6px;
      }

    }
  }
}
