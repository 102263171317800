.balances {
  align-items: center;
  gap: 15px;
}

.balances__item {
  flex-direction: row;
  align-items: center;
  display: none;
  @media (min-width: 992px) {
    display: flex;
  }
  svg {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 3px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.08em;
  }
}