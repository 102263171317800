.market {

}

.market__form {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.market__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  @media (min-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 992px) {
    grid-gap: 22px 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-gap: 22px 20px;
    grid-template-columns: repeat(3, 1fr);
  }
}
