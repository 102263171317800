$mobileMinBreakPoint: 0px;
$mobileMaxBreakPoint: 575px;
$tabletMinBreakPoint: 576px;
$tabletMaxBreakPoint: 991px;
$laptopMinBreakPoint: 992px;
$laptopMaxBreakPoint: 1199px;
$desktopMinBreakPoint: 1200px;

$textColorDefault: #FCFCFD;
$textColorSecondary: #5E636E;

$backgroundDarkPrimary: rgba(20, 1, 50, 1);
$backgroundDarkSecondary: #231252; //rgba(113, 86, 253, 0.2);

$buttonBackgroundPrimary: #7156FB;
$buttonColorPrimary: $textColorDefault;
$buttonBackgroundPrimaryHover: #6206F9;
$buttonColorPrimaryHover: $textColorDefault;
$buttonBackgroundPrimaryDisabled: #36207B;
$buttonColorPrimaryDisabled: #100128;

$textColorError: #E61515;
$borderColorError: #E61515;
$backgroundError: #E61515;

$buttonBorderRadiusDefault: 100px;
$buttonBorderRadiusCircle: 50%;

// OLD
$buttonBackground: #7156FD33;
$buttonBackgroundPrimary: #7156FB;
$buttonBackgroundSecondary: #36207B;
