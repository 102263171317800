@import "variables";
@import "mixins";

.header {
  display: block;
  border-bottom: 1px solid #362866;
  @include fixed-top;
  padding: 16px 0 13px;
  background: rgba(20, 1, 50, 1);
  z-index: 100;
}

.header__row {
  align-items: center;
}

.header__logo {
  display: block;
  margin-right: auto;
  img {
    width: auto;
    height: 30px;
    @media (min-width: 576px) {
      height: 52px;
    }
    @media (min-width: 992px) {
      height: 60px;
    }
  }
}

.header__link {
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
}

.header__divider {
  display: none;
  margin: 0 16px;
  color: #ffffff;
  opacity: .2;
  @media (min-width: 992px) {
    display: block;
  }
}

.p-dropdown-inline {
  margin-right: 0;
}

.btn--auth {
  @media (max-width: 575px) {
    height: 40px;
    padding: 0;
    min-width: 120px;
  }
  @media (max-width: 991px) {
    margin-left: 16px;
  }
}