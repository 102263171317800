@import "variables";
@import "mixins";

.filter {
  //padding-top: 7px;
  height: 100%;
}

.filter__group {
  margin-bottom: 28px;
}

.filter__options {
  display: grid;
  gap: 8px;
  input {
    display: none;
  }
  label {
    display: block;
    gap: 8px;
    border: 2px solid #553D8D;
  }
  &--category {
    input {
      &:checked {
        & + label {
          background: linear-gradient(84.28deg, #AB38BE 0%, #2E1194 47.33%, #00E4AA 100.25%);
          &:hover {
            background: linear-gradient(84.28deg, #AB38BE 10%, #2E1194 47.33%, #00E4AA 90.25%);
          }
        }
      }
    }
    label {
      border: none;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50px;
        border: 2px solid transparent;
        background: linear-gradient(81.59deg, #AB38BE 0%, #2E1194 50.52%, #00E4AA 100%) border-box;
        mask: linear-gradient(#fff 0, #fff 0) padding-box, linear-gradient(#fff 0, #fff 0);
        mask-composite: exclude;
      }
    }
  }
  &--order {
    margin-bottom: 16px;
    input {
      &:checked {
        & + label {
          background: #553D8D;
          position: relative;
        }
      }
    }
    label {
      line-height: 16px;
    }
  }
  &--rarity {
    grid-template-columns: 1fr 1fr;
    label {
      border: none;
      font-size: 12px;
      &:first-of-type {
        grid-column: 3/1;
      }
    }
    input {
      &:checked {
        & + .btn {
          &--starting,
          &--smart,
          &--simple,
          &--rare,
          &--unique,
          &--epiq,
          &--epic,
          &--legendary {
            color: #140132;
            &:before {
              background: #140132;
            }
          }
          &--starting {
            background: #D9C09F;
          }
          &--smart{
            background: linear-gradient(135deg, #6FFFC2 0%, #95E9FF 100%);
          }
          &--simple {
            background: #FBFAFF;
          }
          &--rare {
            background: #00FF00;
          }
          &--unique {
            background: #7156FB;
          }
          &--epiq,
          &--epic {
            background: #FA05F1;
          }
          &--legendary {
            background: #FA7C07;
          }
        }
      }
    }
  }
}

.filter__head {
  gap: 8px;
  margin-bottom: 16px;
}

.filter__title {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 1.4px;
  text-align: left;

}

//.filter {
//
//}
//
//.filter__form {
//
//}
//
//.filter__title {
//  font-size: 14px;
//  line-height: 1;
//  margin: 0;
//}
//

//  display: grid;
//  grid-template-columns: repeat(2, 1fr);
//  gap: 8px;
//  .btn {
//    &:first-of-type {
//      grid-column: 3/1;
//    }
//  }
//  input {
//    display: none;
//  }
//
//  &--order {
//    grid-template-columns: 1fr;
//  }
//
//  &--category {
//    grid-template-columns: 1fr;
//    input {
//      &:checked {
//        & + label {
//          border: none;
//          background: linear-gradient(84.28deg, #AB38BE 0%, #2E1194 47.33%, #00E4AA 100.25%);
//        }
//      }
//      & + label {}
//    }
//  }
//}
//
//
//
//.filter__group {
//  margin-bottom: 28px;
//  .filter__group {
//    margin-bottom: 16px;
//    &:last-child {
//      margin-bottom: 0;
//    }
//  }
//}
//
//.filter__head {
//  gap: 8px;
//}