.modal {
  background:
          linear-gradient(180deg, #10012A 13.11%, #10012A 43.04%, rgba(20, 1, 50, 0) 86.77%, rgba(20, 1, 50, 0) 86.77%),
          linear-gradient(180deg, rgba(113, 86, 251, 0.4) 0%, rgba(113, 86, 251, 0.245833) 45.31%, rgba(113, 86, 251, 0) 100%),
          linear-gradient(180deg, rgba(39, 18, 90, 0) 0%, rgba(37, 16, 86, 0.4) 32.29%, #27125A 100%);
  width: auto;
  height: 100dvh;
  border-radius: 0;
  position: relative;
  padding: 24px 20px 32px;
  z-index: 1;
  margin: 0 auto;
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 0;
    border: 2px solid transparent;
    border-bottom: none;
    background: linear-gradient(180deg, rgba(113, 86, 251, 0.4) 0%, rgba(113, 86, 251, 0.245833) 45.31%,
            rgba(113, 86, 251, 0) 100%) border-box;
    mask: linear-gradient(#fff 0,#fff 0) padding-box,linear-gradient(#fff 0,#fff 0);
    mask-composite: exclude;
    @media (min-width: 768px) {
      border-radius: 40px;
    }
  }

  @media (max-width: 767px) {
    overflow: auto;
  }

  @media (min-width: 768px) {
    width: 600px;
    height: auto;
    border-radius: 40px;
  }
}

.modal__container {
  position: relative;
  z-index: 2;
}

.modal__header {
  margin-bottom: 32px;
  .btn--close {
    margin-left: auto;
    color: #9F92E1;
  }
}

.modal__header-support {
  align-items: center;
  gap: 12px;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: #9F92E1;
}

.modal__footer {
  color: #9F92E1;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: initial;
  margin-top: 32px;
  a {
    color: #01FFC2;
  }

  br {
    display: none;
  }
}