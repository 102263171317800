@import "variables";
@import "mixins";

.layout {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  min-height: 100dvh;
  //overflow: auto;
  //overflow: overlay;
  //position: fixed;
  //left: 0;
  //top: 0;
  //right: 0;
  //bottom: 0;
  //width: 100%;
  //height: 100%;
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 24px;
  @media (min-width: 575px) {
    max-width: 575px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 992px) {
    max-width: 992px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.market-grid {
  display: grid;
  padding-top: 20px;
  padding-bottom: 32px;
  @media (min-width: 992px) {
    grid-template-columns: 238px 1fr;
    grid-gap: 20px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 238px 1fr;
    grid-gap: 48px;
  }
}