.error {
  display: block;
  //position: relative;

  &:before {

    @media (min-width: 768px) {
      content: '';
      display: block;
      width: 1063px;
      height: 970px;
      max-height: 100dvh;
      position: absolute;
      background: url("~src/assets/img/error/error-bg.png") no-repeat 50% 0 / 1063px 970px;
      right: 0;
      top: 0;
    }
  }
}

.error__wrap {
  max-width: 437px;
  text-align: center;
  min-height: calc(100vh - 93px);
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btn {
    max-width: 284px;
  }
}

.error__title {
  font-weight: 900;
  letter-spacing: 0.08em;
  text-align: center;
  margin: 0 0 24px;
  font-size: 32px;
  line-height: 48px;
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 58px;
  }
}

.error__text {

  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  text-transform: initial;
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 32px;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 45px;
  }

}