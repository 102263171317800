.avatar {
  width: 52px;
  height: 52px;
  margin-left: 20px;
  position: relative;
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mask: url('~src/assets/img/avatar-clip.svg');
    mask-size: cover;
  }
}