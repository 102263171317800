.form {

}

.form__group {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.form__control {
  width: 100%;
  border: 2px solid #362866;
  border-radius: 100px;
  text-align: left;
  position: relative;

  .btn--icon {
    position: absolute;
    right: 24px;
    top: 0;
    background: transparent;
    height: 100%;
    z-index: 1;
  }

  input {
    text-transform: initial;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    color: #ffffff;
    padding: 16px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.1em;
    background: transparent;
    position: relative;
    z-index: 1;
    &::placeholder {
      color: #50437C;
      text-transform: uppercase;
    }
  }

  &--error {
    position: relative;
    border: none;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50px;
      border: 2px solid transparent;
      background: linear-gradient(107.46deg, #E3061A 0%, #FA7C07 100%) border-box;
      mask: linear-gradient(#fff 0, #fff 0) padding-box, linear-gradient(#fff 0, #fff 0);
      mask-composite: exclude;

    }
  }
}