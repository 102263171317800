@import "variables";
@import "mixins";

.p-toast {
  position: absolute!important;

  .p-toast-message {
    &.p-toast-message-error {
      margin: 0 0 1rem 0;
      border: none;
      color: $textColorDefault;
      background: linear-gradient(107.46deg, #E3061A 0%, #FA7C07 100%);
      box-shadow: 0 1px 3px rgba(26, 26, 26, 0.15), 0 4px 12px rgba(26, 26, 26, 0.12);
      border-radius: 12px;

      .p-icon-error {
        color: $textColorDefault;
        background: url("~src/assets/img/sprite.svg?v=1#icon-p-toast-error-1") no-repeat 50% 50% / 24px 24px;
        display: block;
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
      }
    }

    .p-toast-message-content {
      padding: 12px 16px;
      border-width: 0;
      align-items: center;

      .p-toast-detail {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.025em;
        color: $textColorDefault;
        margin-top: 0;
      }

      .p-toast-message-text {
        margin: 0 0 0 12px;
      }
    }
  }
}

.p-toast-top-fluid {
  width: 100% !important;
  max-width: calc(100% - 0px);
  top: 12px;
  left: 0;
  @media ((max-width: 1199px) or ((max-height: 924px) and (min-width: 1200px))) {
    position: fixed!important;
    top: 24px;
    //max-width: calc(100% - 40px);
    max-width: 560px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 575px) {
    max-width: calc(100% - 40px);
  }
}


