@import "variables";
@import "mixins";

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  overflow: overlay;
}

.cdk-overlay-backdrop {
  background: rgba(20, 1, 50, 0.8);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(4px);
}

.cdk-global-scrollblock {
  overflow: auto;
}





