.auth {

}

.auth__title {
  width: 100%;
  h2 {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.1em;
    margin: 0;
    text-align: center;
  }
}

.auth__title-wrap {
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;

  .btn {
    & + .auth__title {
      padding: 0 24px;
      margin-left: -24px;
    }
  }
}

.auth__moto {
  text-transform: initial;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.auth__socials {
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 16px;

  &--empty {
    color: #AB9BFD;
    margin: 0;
    @media (min-width: 992px) {
      margin: 0 -115px;
    }
  }

  .btn {
    width: 52px;
    height: 52px;
    padding: 10px;
    background: #FFFFFF;
    &:disabled {
      background: #291856;
      opacity: .4;
    }
  }
}

.auth__agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 32px auto 0;
  text-transform: initial;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  a {
    white-space: nowrap;
    color: #01FFC2;
  }

  br {
    display: none;
  }

}


.auth__agreement-input {
  display: none;

  & + label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    &:before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      border: 2px solid #AB9BFD;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  &:checked + label {
    &:before {
      border-color: #00CC9B;
      background-color: #00CC9B;
      background-image: url("~src/assets/img/sprite.svg#icon-checkmark-white");
      background-position: 1px 1px;
    }
  }
}

.auth__wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &--step1 {
    max-width: 330px;
    text-align: center;
    gap: 16px;
  }
}

.auth__error {
  color: #EE4444;
  text-align: center;
  margin-top: -4px;
  margin-bottom: 12px;
  text-transform: initial;
}

.auth__group-btn {
  flex-direction: row;
  gap: 12px;
  .btn {
    width: 100%;
  }
}

.auth__actions {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}