.color {
  &-starting {
    color: #D9C09F;
  }
  &-smart{
    color: #6FFFC2;
  }
  &-simple {
    color: #FBFAFF;
  }
  &-rare {
    color: #00FF00;
  }
  &-unique {
    color: #7156FB;
  }
  &-epic,
  &-epiq {
    color: #FA05F1;
  }
  &-legendary {
    color: #FA7C07;
  }
  &-gradient {
    background: linear-gradient(89.84deg, #FA05F1 36.94%, #7156FB 49.77%, #00E4AA 63.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}