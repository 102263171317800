@mixin reset-bounding() {
  padding: 0;
  margin: 0;
}

@mixin reset-list() {
  @include reset-bounding();
  list-style: none;
}

@mixin reset-link {
  text-decoration: none;
  color: inherit;
}

@mixin reset-btn {
  @include reset-bounding();
  background: transparent;
  border: none;
  &:enabled {
    cursor: pointer;
  }
}

@mixin fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}