.confirm {
  text-align: center;
}

.confirm__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 0 8px;
}

.confirm__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: initial;
}

.confirm__actions {
  margin-top: 32px;
  gap: 12px;
  .btn {
    width: 100%;
  }
}