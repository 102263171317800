@import "variables";
@import "mixins";

.btn {
  position: relative;
  display: inline-block;
  font: inherit;
  text-transform: uppercase;
  text-align: center;
  @include reset-btn;
  border-radius: 100px;
  border: none;
  padding: 16px 0;
  letter-spacing: inherit;
  font-weight: 700;
  cursor: pointer;
  height: 52px;
  line-height: 20px;
  transition: all .3s;
  &:disabled {
    cursor: not-allowed;
  }
  
  span {
    display: block;
  }

  &--close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #291856;
    color: #140132;
    padding: 6px;
  }

  &--icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #27125A;
    color: #9F92E1;
    padding: 4px;
  }

  &--email {
    &:disabled {
      color: #6C6094;
    }
  }

  &--link {
    border: none;
    padding: 0;
    border-radius: 0;
    height: auto;
    display: inline-block;
    color: #AB9BFD;
    line-height: 1;

    &.btn--sm {
      color: #50437C;
      text-transform: initial;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }

  &--disabled {
    background: #140132;
    color: #AB9BFD;
    width: 100%;
    height: 52px;
    text-align: center;
    border-radius: 100px;
    border: none;
    font-weight: 700;
  }

  &--md {
    height: 44px;
    padding: 0px;
  }

  &--auth {
    background: linear-gradient(81.59deg, #AB38BE 0%, #2E1194 50.52%, #00E4AA 100%);
    color: $buttonColorPrimary;
    min-width: 156px;
    &:hover {
      background: linear-gradient(84.28deg, #AB38BE 10%, #2E1194 47.33%, #00E4AA 90.25%);
    }
  }

  &--starting,
  &--smart,
  &--simple,
  &--rare,
  &--unique,
  &--epiq,
  &--epic,
  &--legendary {
    border-color: transparent;
    letter-spacing: 1.2px;
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  &--starting {
    background: #26123C;
    color: #D9C09F;
    &:before {
      background: #D9C09F;
    }
  }
  &--smart{
    background: #1B103C;
    color: #89F0EB;
    &:before {
      background: #89F0EB;
    }
  }
  &--simple {
    background: #22103E;
    color: #FFFFFF;
    &:before {
      background: #FFFFFF;
    }
  }
  &--rare {
    background: #13102F;
    color: #00FF00;
    &:before {
      background: #00FF00;
    }
  }
  &--unique {
    background: #1C0944;
    color: #7156FB;
    &:before {
      background: #7156FB;
    }
  }
  &--epiq,
  &--epic {
    background: #22023E;
    color: #FF0DFF;
    &:before {
      background: #FF0DFF;
    }
  }
  &--legendary {
    background: #220A2F;
    color: #FA7C07;
    &:before {
      background: #FA7C07;
    }
  }
  
  &--primary {
    width: 100%;
    height: 52px;
    text-align: center;
    background: linear-gradient(84.28deg, #AB38BE 0%, #2E1194 47.33%, #00E4AA 100.25%);
    border-radius: 100px;
    color: #FFFFFF;
    border: none;
    font-weight: 700;
    &:hover {
      background: linear-gradient(84.28deg, #AB38BE 10%, #2E1194 47.33%, #00E4AA 90.25%);
    }
    span {

      &:first-child {
        font-size: 18px;
      }

      &:last-child {
        font-size: 14px;
        line-height: 1;
        font-weight: 400;
        margin-top: 1px;
      }
    }
    &.p-0 {
      padding: 0;
    }
    &:disabled {
      border: none;
      color: #6C6094;
      background: #291856;
    }
  }

  &--lg {
    height: 80px;
    padding: 30px 0;
  }

  &--sm {
    text-transform: initial;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-align: left;
  }

  &--gradient-outline {
    width: 100%;
    border: none;
    color: #FFFFFF;
    background: #140132;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50px;
      border: 2px solid transparent;
      background: linear-gradient(81.59deg, #AB38BE 0%, #2E1194 50.52%, #00E4AA 100%) border-box;
      mask: linear-gradient(#fff 0, #fff 0) padding-box, linear-gradient(#fff 0, #fff 0);
      mask-composite: exclude;
    }
    &:disabled {
      border: 2px solid #50437C;
      color: #6C6094;
      padding: 14px 0;
      &:before {
        display: none;
      }
    }
  }
}

