.market-card {
  border-radius: 20px;
  display: block;
  overflow: hidden;
}

.market-card__top {
  position: relative;
  display: block;
  padding-top: 24px;
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 2px solid transparent;
    border-bottom: none;
    background: linear-gradient(180deg, rgba(113, 86, 251, 0.4) 0%, rgba(113, 86, 251, 0.245833) 45.31%, rgba(113, 86, 251, 0) 100%) border-box;
    mask: linear-gradient(#fff 0,#fff 0) padding-box,linear-gradient(#fff 0,#fff 0);
    mask-composite: exclude;
  }
}
.market-card__bottom {
  padding: 24px 12px;
  background: linear-gradient(180deg, rgba(39, 18, 90, 0) 0%, #27125A 100%);
}

.market-card__head {
  text-align: center;
  margin-bottom: 32px;
  p {
    font-size: 18px;
    line-height: 14px;
    letter-spacing: 1.8px;
    font-weight: 700;
    &:last-child {
      margin-top: 8px;
      color: #9F92E1;
      font-weight: 500;
      letter-spacing: calc(18px / 100 * 2);
      text-transform: lowercase;
    }
  }

}

.market-card__image {
  width: 100%;
  height: 190px;
  position: relative;
  flex-wrap: wrap;

  @media (min-width: 575px) {
    height: 167px;
  }
  @media (min-width: 768px) {
    height: 138px;
  }
  @media (min-width: 992px) {
    height: 195px;

    &--stock {
      height: 215px;
    }
  }
  @media (min-width: 1200px) {
    height: 167px;
  }

  img {
    position: absolute;
    bottom: 0;
    width: 80%;
    height: auto;
    max-width: 250px;

    left: 50%;
    transform: translateX(-50%);
  }

  img.market-card__image-podium {
    background: #140132;
  }

  &--stock {
    img {
      width: 80%;
    }
  }

  &--case {
    img {
      width: 100%;
    }
  }

  &--old-glasses {
    img {
      width: 45%;
    }
    @media (min-width: 575px) {
      img {
        width: 80%;
      }
    }
    @media (min-width: 768px) {
      img {
        width: 80%;
      }
    }
    @media (min-width: 992px) {
      img {
        width: 75%;
      }
    }
    @media (min-width: 1200px) {
      img {
        width: 80%;
      }
    }
  }
}

.market-card__meta {
  padding-bottom: 6px;
  margin-top: auto;
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 4.8px;
    width: 100%;
    &.id {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #9F92E1;
    }
  }

  &--glasses {
    position: relative;
    width: 100%;
    bottom: 0;
  }

  &--overlay {
    position: absolute;
  }
}

.market-card__info {
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 24px;
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 6px;
    color: rgba(171, 155, 253, 1);
    span {
      color: rgba(255, 255, 255, 1);
      margin-left: auto;
      font-size: 18px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.1em;
      text-align: right;
    }
  }
}

.market-card__stock-info {
  font-size: 18px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(171, 155, 253, 1);
}

.market-card__info-row {
  width: 100%;
  &--stock {
    p {
      justify-content: space-between;
      &:nth-of-type(2) {
        span {
          color: rgba(171, 155, 253, 1);
          font-size: 18px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0.02em;
          text-align: left;
        }
      }
      span {
        margin-left: 0;
      }
    }


  }
}



//.market-card {
//  width: 100%;
//  min-height: 590px;
//  border-radius: 20px;
//  background: rgba(27, 13, 64, 1);
//  padding: 16px 20px 24px;
//}
//
//.market-card__image {
//  height: 192px;
//  border-radius: 12px;
//  background: radial-gradient(100% 100.00% at 50.00% 0%, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%), #140132;
//  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.20) inset, 0 0 2px 0 rgba(0, 0, 0, 0.83) inset;
//  overflow: hidden;
//  margin-left: -12px;
//  margin-right: -12px;
//  margin-bottom: 10px;
//  img {
//    display: block;
//    width: 224px;
//    //height: 100%;
//    //height: 158px;
//    margin: -61px auto 0;
//  }
//}
//
//.market-card__name {
//  font-size: 10px;
//  line-height: 1;
//  font-weight: 700;
//  text-align: center;
//  &--starting {color: #D9C09F;}
//  &--smart {color: #89f0eb;}
//  &--simple {color: #fff;}
//  &--rare {color: #0F0;}
//  &--unique {color: #7156FB;}
//  &--epic {color: #FA05F1;}
//  &--legendary {color: #FA7C07;}
//}
//
//.market-card__header {
//  font-size: 10px;
//  line-height: 1;
//  letter-spacing: 1px;
//  color: #AB9BFD;
//  font-weight: 700;
//  margin-bottom: 9px;
//  span {
//    margin-left: auto;
//  }
//}
//
//.market-card__info {
//  justify-content: space-between;
//  margin-bottom: 15px;
//  p {
//    font-weight: 700;
//    color: #ffffff;
//    &:last-child {
//      text-align: right;
//    }
//  }
//
//  span {
//    display: block;
//    &:first-child {
//      font-size: 18px;
//      line-height: 1;
//      letter-spacing: 0;
//    }
//    &:last-child {
//      color: #AB9BFD;
//      font-size: 10px;
//      line-height: 1;
//      letter-spacing: 1px;
//    }
//  }
//}
//
//.market-card__slots {
//  justify-content: space-between;
//  margin-bottom: 16px;
//}
//
//.market-card__modules {
//  margin-bottom: 16px;
//  li {
//    font-size: 8px;
//    line-height: 1;
//    font-weight: 700;
//    margin-bottom: 16px;
//    &:last-child {
//      margin-bottom: 0;
//    }
//    span {
//      margin-left: auto;
//    }
//  }
//}
