.progress {
  width: 100%;
  background: rgba(171, 155, 253, 0.15);
  border-radius: 20px;
  height: 6px;
  span {
    display: block;
    height: 100%;
    border-radius: 20px;
    width: 0;
  }
  &--endurance {
    span {
      background: linear-gradient(90deg, #5C48D7 0%, #C056FB 100%);
    }
  }
  &--level {
    span {
      background: linear-gradient(90deg, #0077CD 0%, #00E4AA 100%);
    }
  }
}