@import "variables";
@import "mixins";

*,*:before,*:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background: $backgroundDarkPrimary;
  color: $textColorDefault;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

a {
  @include reset-link;
  &:hover {

  }
}

.total-text {
  a {
    color: #00E4AA;
  }
}

ul {
  @include reset-list;
}

p {
  margin: 0;
}

svg {
  display: block;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.ml-auto {
  margin-left: auto;
}