.p-overlaypanel {
  padding: 0;
  background: transparent;
  &:after,
  &:before {
    display: none;
  }
  .p-overlaypanel-content {
    padding: 0;
  }
}

.p-overlay {
  left: initial !important;
  right: 0;
  max-width: calc(100vw - 48px);
}